.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-btn-primary,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #721FB5 !important;
  border-color: #721FB5 !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #721FB5 !important;
  opacity: 0.1;
}
.ant-btn-primary:hover {
  background-color: #721FB5 !important;
  border-color: #721FB5 !important;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #721FB5 !important;
}
.ant-modal-header,
.ant-modal-footer {
  border: none !important;
}
.ant-modal-body {
  padding: 8px 24px !important;
}
.ant-switch-checked {
  background-color: #721FB5 !important;
}
.ant-menu-item-selected > a, .ant-menu-submenu-selected {
  color: #721FB5 !important;
}
.ant-menu-item > a:hover, .ant-menu-submenu-selected:hover {
    color: #721FB5 !important;
}
.ant-tabs-nav {
  margin-top: 10px !important;
}