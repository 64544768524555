body {
  background-color: #f0f2f5 !important;
}

ol,
ul {
  padding: 0px;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
pre {
  padding: 0;
  margin: 0 !important;
}

* {
  font-family: "Quicksand";
  font-weight: 600;
}

.loaderFixed {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #721fb5;
  z-index: 99999;
}

.bg-white {
  background-color: white;
}

.ant-table {
  background-color: white;
}
.ant-table-pagination.ant-pagination {
  margin: 15px 0 0 0 !important;
}
.w-100 {
  width: 100% !important;
}
.fl {
  float: left;
}
.floater {
  float: left;
  width: 100%;
}

.flex {
  display: flex;
}
.flex.center {
  justify-content: center;
  align-items: center;
}
.bold {
  font-weight: bold;
}
/* colors */
.red {
  color: red;
}

/* loader */
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.goBack {
  padding: 10px;
  background-color: white;
  width: 70px;
  text-align: center;
  cursor: pointer;
  margin-right: 20px !important;
  float: left;
}

.flex {
  display: flex;
  align-items: center;
}

/* widths */
.w-100 {
  width: 100%;
  float: left;
}

/* margins */
.mt-10 {
  margin-top: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.ml-10 {
  margin-left: 10px;
}
.ml-5 {
  margin-left: 5px;
}
.margin0 {
  margin: 0 !important;
}

/* theme edit */
.theme-edit-parts {
  float: left;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 2px solid black;
  padding-bottom: 10px;
}
.two-items {
  display: flex;
  justify-content: space-between;
}
.two-items .two-item {
  width: 48%;
}

/* message part */
.message-part {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
.message-part span {
  font-weight: bold;
}

/* Add css */
.b-page-top {
  width: 100%;
  float: left;
  height: auto;
  background-color: white;
  margin-bottom: 10px;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.b-add-box {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: auto;
}

.b-add-box .b-add-box-left {
  width: 49%;
  height: 100%;
  background-color: white;
  padding: 30px 20px;
  min-height: 50px;
  border-radius: 3px;
}

.b-add-box .b-add-box-left label {
  width: 100%;
  font-weight: bold;
}

.b-add-box .b-add-box-left input,
.b-add-box .b-add-box-left textarea {
  border: 1px solid #d9d9d9;
  width: 100%;
  border-radius: 4px;
  padding: 10px;
}

.b-add-box .b-add-box-left input,
.b-add-box .b-add-box-left textarea,
.b-add-box .b-add-box-left .ant-select {
  margin: 5px 0;
}

.b-add-box .b-add-box-left .ant-calendar-picker-input.ant-select {
  margin: 0 !important;
}

.b-add-box .b-add-box-left .two-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  float: left;
}

.b-add-box .b-add-box-left .two-items .multi-item {
  width: 47% !important;
}

.b-add-box .b-add-box-left .two-items .multi-item .multi-item-content {
  width: 100% !important;
}

.b-add-box .b-add-box-left .two-items .multi-item .multi-item-content input {
  margin: 8px 0;
}

.b-add-box .b-add-box-right {
  min-height: 50px;
  width: 49%;
  height: 100%;
  padding: 30px 20px;
  background-color: white;
  border-radius: 3px;
}

@media only screen and (max-width: 994px) {
  .b-add-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .b-add-box .b-add-box-left,
  .b-add-box .b-add-box-right {
    width: 100%;
    float: left;
    margin-bottom: 20px;
  }
}

.b-section {
  margin: 10px 0;
}

.b-section:first-child {
  margin-top: 0;
}

.b-section:last-child {
  margin-bottom: 0;
}

.ant-select-search__field {
  opacity: 0;
}

.add-organization .block-of-two {
  width: 100%;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.add-organization .block-of-two .block-of-two-item {
  width: 48%;
}

.add-organization .media label {
  margin-bottom: 5px;
}

.mt10 {
  margin-top: 8px !important;
}

.edit-parts {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.edit-part {
  width: 48%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  float: left;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.edit-part label,
.edit-part .label-field {
  width: 100%;
  float: left;
}

.edit-question {
  width: 100%;
  float: left;
}
/*# sourceMappingURL=Add.css.map */
/* add css ends */

/* .ck-editor__editable {
  min-height: 300px !important;
  padding: 15px !important;
} */

.fr {
  float: right;
}
.fl {
  float: left;
}

/* Spinner */
.ant-spin-dot-item {
  background-color: white !important;
}
.ant-spin-dot {
  font-size: 18px !important;
  width: 0.8em !important;
  height: 0.8em !important;
}
.ant-spin-spinning {
  margin-left: 10px !important;
  height: 20px !important;
}

.table-img {
  height: 50px;
}

.search-input input {
  height: 30px;
}

/* editable table */
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.pointer {
  cursor: pointer;
}

.table-head {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled-btn button {
  width: 100%;
  color: white !important;
}
.disabled-btn {
  width: 100%;
  color: white !important;
}
.all-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.accordion-tab {
  width: 100%;
  transition: all 0.5s ease;
  transition: opacity 0.1s ease;
}
.accordion-tab.closed {
  height: 0px;
  opacity: 0;
}
.accordion-tab.opened {
  height: auto;
}
.translation-box {
  padding-bottom: 20px;
  border-bottom: 1px solid gray;
}

.bottomRightSpinner {
  position: absolute !important;
  bottom: 30px;
  right: 30px;
  width: 100px;
}

.pad-left {
  margin-left: 20px;
}
